<template>
  <div class="login">
    <div class="login-container">
      <div>
        <img class="login-logo" src="../assets/img/logo.png" />
        <div class="login-top">
          <label
            class="login-title"
            v-show="seccion == 'usuario' || seccion == 'password'"
            >Iniciar sesión</label
          >
          <label class="login-title" v-show="seccion == 'recuperar'"
            >Recuperar contraseña</label
          >
          <label class="login-title" v-show="seccion == 'tipo'"
            >Tipo de usuario</label
          >
          <label class="login-subtitle" v-show="seccion == 'tipo'"
            >Selecciona tipo de usuario</label
          >
        </div>
        <transition mode="out-in">
          <div v-if="seccion == 'usuario'">
            <label class="login-label" for="usuario">Usuario</label>
            <label class="login-error" v-show="errorUsuario != ''">{{
              errorUsuario
            }}</label>
            <div
              class="input-primary"
              :class="{ 'input-error': errorUsuario != '' }"
            >
              <input
                id="usuario"
                type="text"
                v-model="usuario"
                placeholder="Escribe tu usuario"
                v-on:keyup.enter="chequearUsuario()"
              />
              <img
                class="input-img input-img-close"
                src="../assets/img/icon/close.png"
                @click="usuario = ''"
              />
            </div>
          </div>
          <div v-else-if="seccion == 'password'">
            <img class="login-label-img" src="../assets/img/icon/candado.png" />
            <label class="login-label" for="password">Contraseña</label>
            <label class="login-error" v-show="errorPassword != ''">{{
              errorPassword
            }}</label>
            <div
              class="input-primary"
              :class="{ 'input-error': errorPassword != '' }"
            >
              <input
                id="password"
                :type="passwordType"
                v-model="password"
                ref="password"
                placeholder="Ingresa tu contraseña"
                v-on:keyup.enter="chequearPassword()"
              />
              <img
                v-show="passwordType == 'password'"
                class="input-img"
                src="../assets/img/icon/ojo.png"
                @click="passwordType = 'text'"
              />
              <img
                v-show="passwordType == 'text'"
                class="input-img"
                src="../assets/img/icon/ojo-active.png"
                @click="passwordType = 'password'"
              />
            </div>
            <label class="login-recuperar" @click="recuperar()"
              >¿Has olvidado la contraseña?</label
            >
          </div>
          <div v-else-if="seccion == 'recuperar'">
            <img
              class="login-recuperar-img"
              src="../assets/img/login/mail.png"
            />
            <p class="login-recuperar-text">
              ¡Revisa tu mail!, enviamos un link para recuperar contraseña
            </p>
          </div>
          <div v-else-if="seccion == 'tipo'" class="login-tipo-container">
            <div class="login-tipo">
              <img src="../assets/img/login/supervisor.png" />
              <label>Supervisor</label>
            </div>
            <div class="login-tipo">
              <img src="../assets/img/login/guardia.png" />
              <label>Guardia</label>
            </div>
          </div>
        </transition>
        <button
          v-show="seccion == 'usuario'"
          class="button-primary login-btn"
          @click="chequearUsuario()"
        >
          Continuar
        </button>
        <button
          v-show="seccion == 'password'"
          class="button-primary login-btn"
          @click="chequearPassword()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      usuario: "",
      password: "",
      errorUsuario: "",
      errorPassword: "",
      seccion: "usuario",
      passwordType: "password",
    };
  },
  mounted() {
    localStorage.removeItem("$token");
    this.$axios.defaults.headers.common["Authorization"] = null;
  },
  methods: {
    chequearUsuario() {
      if (this.usuario == "" || this.usuario == null) {
        this.errorUsuario = "Debe ingresar un usuario";
      } else {
        let formData = new FormData();
        formData.append('username', this.usuario);
        var that = this;
        this.$axios
          .put(this.$serverURL + "/api/user/buscar/", formData)
          .then(function () {
          })
          .catch(function (response) {
            if(response.response.status == 302){
              that.errorUsuario = "";
              that.seccion = "password";
              setTimeout(() => {
                that.$refs.password.focus();
              }, 150);
            }else{
              that.errorUsuario = "Usuario inexistente";              
            }
          });
      }
    },
    chequearPassword() {
      if (this.password == "" || this.password == null) {
        this.errorPassword = "Debe ingresar una contraseña";
      } else {
        this.errorPassword = "";
        // this.seccion = "tipo";
        let json = {
          username: this.usuario,
          password: this.password,
        };
        var that = this;
        this.$axios
          .post(this.$serverURL + "/api/user/token/", json)
          .then(function (response) {
            console.log(response.data.token);
            localStorage.setItem("$token", response.data.token);
            that.$axios.defaults.headers.common["Authorization"] =
              "Token " + response.data.token;
            that.$emitter.emit("logged");
            that.$router.push("/");
          })
          .catch(function () {
            localStorage.removeItem("$token");
            that.$axios.defaults.headers.common["Authorization"] = null;
            that.errorPassword = "Contraseña incorrecta";
          });
      }
    },
    recuperar() {
      var that = this;
      let json = {
        username: this.usuario,
      };
      this.$axios
        .put(this.$serverURL + "/api/user/sendemail/", json)
        .then(function (response) {
          console.log(response.data);
          that.seccion = "recuperar";
        })
        .catch(function () {
          that.errorPassword = "Error recuperando contraseña";
        });
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/views/login.scss"></style>
